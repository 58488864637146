import React from 'react';

const Label = (props) => {
    const {onClick, small, toggle, style, sub, badge, children, UIVariation} = props;
    return (
        <label onClick={onClick} className={`Label ${small ? `Label--small` : ``} ${toggle ? `Label--toggle` : ``} ${UIVariation == 'brand-shift-1' ? `Label--bs` : ``}`}>
            { sub &&
                <div className='Label__sub'>
                    {sub}
                </div>
            }
            { badge &&
                <div className='Label__badge'>
                    <div className='Label__badge__container'>
                        {badge}
                    </div>
                </div>
            }

            <div className='Label__text' style={style}>
                {children}
            </div>
        </label>
    );
}

export default Label;