import React from 'react';

const Input = (props) => {

    const {
        UIVariation,
        type='text',
        placeholder,
        handleChange,
        setRef=null,
        required,
        value,
        defaultValue,
        error = false,
        name,
        style,
        handleErrorChange = () => {},
        autofocus = false,
    } = props;
    return (
        <div className={`Input ${UIVariation == 'brand-shift-1' ? 'Input--bs' : ''} ${error ? 'Input--error' : ''}`}>
            <input
                data-lpignore='true'
                autoFocus={autofocus}
                className={`Input__input`}
                ref={setRef}
                id={`input_${name}`}
                type={props.type ? type : 'text'}
                placeholder={placeholder}
                onChange={e => {handleChange(e.target.value);}}
                required={required}
                name={name}
                value={value}
                defaultValue={defaultValue}
                style={{...style.inputStyles.input}} 
            />
        </div>
    );
}
 
export default Input;